






























import {
  Component,
  Vue,
  Prop,
} from 'vue-property-decorator';

import Checkbox from '@/components/Input/Checkbox/index.vue';

import HistoricService from '@/services/Historic/historicService';

import ActiveModal from '@/share/Util/ActiveModal';

@Component({
  components: {
    Checkbox,
  },
})
export default class ExamItem extends Vue {
  @Prop() title!: string;
  @Prop() checked!: boolean;
  @Prop({ default: false }) permissionExam!: boolean;
  @Prop() file!: Record<string, any>;
  @Prop() allChecked!: Array<Record<string, any>>;
  @Prop() setCheckbox!: Function;

  private setModal = new ActiveModal();
  private historicService = new HistoricService();

  private show = false;

  async openExamRelated() {
    if (!this.permissionExam) {
      this.setModalPermission();
      return;
    }

    this.setPDF();
  }

  setModalPermission() {
    this.setModal.activeModal('modalNotContentPlan');
  }

  async setCheckboxLocal(type: string, checked: boolean) {
    this.show = !checked;

    await this.historicService.saveHistoric({
      idobject: this.file.fileID,
      type: 'vestibular',
      checked: this.show,
    });

    this.allChecked.forEach((item, index) => {
      if (item.idobject === this.file.fileID) {
        this.allChecked[index].checked = this.show;
      }
    });
  }

  setPDF() {
    const { idExam } = this.$route.params;

    this.$router.push({
      name: 'ExamViewer',
      params: {
        idExam,
        idFile: this.file.fileID,
        slug: this.file.urlFile,
      },
    });
  }
}
