



























































import { Component, Prop, Vue } from 'vue-property-decorator';

import Checkbox from '@/components/Input/Checkbox/index.vue';
import Tooltip from '@/components/Tooltip/index.vue';

import iDownload from '@/assets/icons/DownloadTab.vue';
import Lock from '@/assets/icons/Lock.vue';

import { InstanceAmplitude } from '@/share/Util/Metrics/Metrics';
import ActiveModal from '@/share/Util/ActiveModal';

import { LIST_PLAN_ID } from '@/constant/PlansIDs';
import { DEFAULT_COPY_MODAL_PLAN_UPDATE } from '@/constant/Modal';
import { LIST_ID_EXAMS_GUIA_DO_VESTIBULADO } from '../../constants';

@Component({
  components: {
    Checkbox,
    iDownload,
    Lock,
    Tooltip,
  },
})
export default class ListFiles extends Vue {
  @Prop({ default: true }) showDownload!: boolean;
  @Prop({ default: false }) hasPermission!: boolean;
  @Prop() padding!: string;
  @Prop() listItem!: Array<Record<string, any>>;
  @Prop() allChecked!: Array<Record<string, any>>;
  @Prop() setCheckbox!: Function;
  @Prop() setChecked!: Function;

  private setModal = new ActiveModal();

  beforeDestroy() {
    this.$store.commit('setModalPlanUpdate', DEFAULT_COPY_MODAL_PLAN_UPDATE);
  }

  get isPlanFree() {
    return this.$store.getters.profile?.plan?.planID === LIST_PLAN_ID.FREE;
  }

  get idExam() {
    return this.$route.params.idExam;
  }

  checked(id: number) {
    const result = this.allChecked.some((checkedItem: Record<string, any>) => {
      if (checkedItem && checkedItem?.idobject === id) {
        return checkedItem.checked;
      }
      return false;
    });
    return result;
  }

  openPDF(item: Record<string, any>) {
    if (!this.hasPermission) {
      this.setShowModal();
      return;
    }

    this.setCheckedFile(item);

    this.$router.push({
      name: 'ExamViewer',
      params: {
        idExam: this.idExam,
        idFile: item.fileID,
        slug: item.urlFile,
      },
    });

  }

  setShowModal() {
    if (this.isPlanFree) this.showModalPlanUpdate();
    else this.showModalPermission();
  }

  showModalPlanUpdate() {
    const isGuiaDoVestibulandoCategory = LIST_ID_EXAMS_GUIA_DO_VESTIBULADO.includes(Number(this.idExam));

    this.$store.commit('setModalPlanUpdate', {
      title: `Você precisa ser aluno premium para acessar ${isGuiaDoVestibulandoCategory ? 'o Guia do Vestibulando' : 'as Provas de Vestibulares'}.`,
      description: 'Por menos de R$1,40 por dia, você pode ter acesso ilimitado a tudo que o Explicaê oferece.',
    });

    this.setModal.activeModal('modalPlanUpdate');
  }

  showModalPermission() {
    this.setModal.activeModal('modalNotContentPlan');
  }

  setDownload(item: Record<string, any>) {
    if (!this.hasPermission) {
      this.setShowModal();
      return;
    }

    this.setCheckedFile(item);

    window.open(`https://explicae.com.br/app/${item.url}`, '_blank');
  }

  setCheckedFile(item: Record<string, any>) {
    this.setChecked(item, this.checked(item.fileID));

    this.setTrackAmplitude(item);
  }

  setTrackAmplitude(item: Record<string, any>) {
    InstanceAmplitude.setTrack({
      eventName: 'component_click',
      additionalAttrs: {
        data: {
          component: 'ListFiles',
          local: 'Vestibulares',
          questionTitle: item?.title,
        },
      },
    });
  }
}
