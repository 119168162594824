






















































































import { Component, Mixins, Watch } from 'vue-property-decorator';

import PageHeader from '@/components/PageHeader/PageHeader.vue';
import WrapperContent from '@/components/WrapperContent/WrapperContent.vue';
import PdfViewer from '@/components/PdfViewer/PdfViewer.vue';
import PdfViewerLoading from '@/components/PdfViewer/PdfViewerLoading.vue';
import OptionsContent from '@/components/OptionsContent/OptionsContent.vue';
import Footer from '@/components/Footer/Footer.vue';
import ModalError from '@/components/Modal/ModalError/ModalError.vue';
import ContainerFluid from '@/components/ContainerFluid/ContainerFluid.vue';

import ExamsHeaderAccordion from './components/ExamsHeaderAccordion/index.vue';
import ExamsAccordion from './components/ExamsAccordionViewer/index.vue';
import ViewerContentListLoading from '@/components/ViewerContentListLoading/index.vue';

import ActiveModal from '@/share/Util/ActiveModal';

import HistoricService from '@/services/Historic/historicService';
import ExamsService from '@/services/Exams/ExamsService';

import Permission from '@/mixins/permission';

import { LIST_PERMISSION } from '@/constant/ListPermission';
import { LIST_ID_BOOK_QUESTION_FOR_PERMISSION } from '@/constant/ListIdsBookQuestion';

import type { DataExam, File, ListCheckedExams } from './interfaces/InterfaceExamViewer';

const DATA_EXAM_EMPTY = {
  category: {},
  file: {},
  related: [],
  relatedCount: 0,
  exam: {},
};

@Component({
  components: {
    PageHeader,
    WrapperContent,
    OptionsContent,
    PdfViewer,
    PdfViewerLoading,
    ModalError,
    ExamsHeaderAccordion,
    ExamsAccordion,
    ViewerContentListLoading,
    Footer,
    ContainerFluid,
  },
})
export default class ExamViewer extends Mixins(Permission) {
  private pdf = '';
  private isLoading = false;
  private relatedExamsIsOpen = true;
  private errorDataExam = false;
  private currentChecked = false;
  private idsExams: Array<number> = [];
  private allCheckedExams: Array<ListCheckedExams> = [];
  private dataExam: DataExam | Record<string, any> = DATA_EXAM_EMPTY;

  private historicService = new HistoricService();
  private examService = new ExamsService();
  private setModal = new ActiveModal();

  get isMobile() {
    return this.$responsive.isMobile;
  }

  get permissionDownloadMaterials() {
    return this.can(LIST_PERMISSION.MATERIALS_DOWNLOAD);
  }

  get permissionExam() {
    return this.can(LIST_ID_BOOK_QUESTION_FOR_PERMISSION[Number(this.idExam)]);
  }

  get isMovie() {
    return this.$store.getters.isMovie;
  }

  get idExam() {
    return this.$route.params.idExam;
  }

  get currentExamID() {
    return this.$route.params.idFile;
  }

  @Watch('currentExamID', {
    immediate: true,
  })
  async updateExamData() {
    await this.getExam();
    this.getIdsExamsRelated(this.dataExam?.related);
    await this.setAllCheckedRelated();
    this.setCheckedCurrent(this.dataExam.file, false);
  }

  async getExam() {
    try {
      this.isLoading = true;

      const dataExam = await this.examService.getExamRelated(this.currentExamID);

      if (dataExam) this.dataExam = dataExam;
    } catch (error) {
      this.errorDataExam = true;
      this.showModalError();
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar os dados da página.',
        status: 'error',
      });
    } finally {
      this.isLoading = false;
    }
  }

  async setAllCheckedRelated() {
    const listIds = this.idsExams.filter(Boolean);

    let response = [];

    if (!listIds.length) response = [];
    else response = await this.getHistoricCheckedExam(listIds);

    this.allCheckedExams.push(...response);
  }

  async getHistoricCheckedExam(idsExam: Array<number>) {
    try {
      const historic = await this.historicService.getHistoric({ type: 'vestibular', listIds: idsExam }) || [];

      return historic;
    } catch (error) {
      return [];
    }
  }

  async setCheckbox(file: File, checked: boolean) {
    await this.historicService.saveHistoric({
      idobject: file.fileID,
      type: 'vestibular',
      checked: !checked,
    });

    this.updateAllCheckedExams();
  }

  setCheckedCurrent(file: File, checked: boolean) {
    this.setCheckbox(file, checked);

    this.currentChecked = !checked;
  }

  updateAllCheckedExams() {
    this.allCheckedExams = this.allCheckedExams.map((exam: ListCheckedExams) => {
      if (exam.idobject === Number(this.currentExamID)) {
        return {
          ...exam,
          checked: this.currentChecked,
        };
      }
      return exam;
    });
  }

  getIdsExamsRelated(listExams: Array<File>) {
    this.idsExams = listExams.reduce((ids: Array<number>, examRelated: File) => [...ids, examRelated?.fileID], []);
  }

  isCheckedExam(idExam: number) {
    const checkResult = this.allCheckedExams.some((exam: ListCheckedExams) => {
      if (exam?.idobject === idExam) {
        if (idExam === Number(this.currentExamID)) return true;
        return exam.checked;
      }
      return false;
    });

    return checkResult;
  }

  setOpeningRelatedExams() {
    this.relatedExamsIsOpen = !this.relatedExamsIsOpen;
  }

  reloadPage() {
    window.location.reload();
  }

  showModalError() {
    this.setModal.activeModal('modalError');
  }

  @Watch('dataExam')
  setPDF() {
    if (this.dataExam) {
      const { urlFile } = this.dataExam?.file;
      const { token } = this.$store.getters;

      this.pdf = `https://explicae.com.br/app/ver/apostila/${urlFile}?token=${token}`;
    }
  }

  @Watch('dataExam')
  setBreadCrumbs() {
    const titlePDF = this.dataExam?.file?.title;
    const titleExam = this.dataExam?.exam?.title;
    const idExam = this.dataExam?.exam?.idQuestion;

    if (titlePDF && titleExam && idExam) {
      this.$breadcrumb.set([
        { title: 'vestibulares', to: '/vestibular' },
        { title: titleExam, to: `/vestibular/${titleExam.replace(' ', '')}/${idExam}` },
        { title: `${titlePDF.slice(0, 13)}...`, to: null },
      ]);
    } else {
      this.$breadcrumb.set([
        { title: 'vestibulares', to: '/vestibular' },
      ]);
    }
  }
}
