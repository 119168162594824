






























import { Component, Vue, Prop } from 'vue-property-decorator';

import FeedbackUser from '@/components/FeedbackUser/index.vue';

import ExamItem from '../ExamItem/index.vue';

import ProblemFilled from '@/assets/icons/ProblemFilled.vue';

import { File, ListCheckedExams } from '../../interfaces/InterfaceExamViewer';

@Component({
  components: {
    FeedbackUser,
    ExamItem,
    ProblemFilled,
  },
})
export default class ExamsAccordionViewer extends Vue {
  @Prop() filesRelated!: Array<File>
  @Prop() allChecked!: Array<ListCheckedExams>
  @Prop() setCheckbox!: Function;
  @Prop() isChecked!: Function;
  @Prop() errorData!: boolean;
  @Prop({ default: false }) permissionExam!: boolean;
}
